import React from 'react';
import One from '../img/activeg/1.png';
import Two from '../img/activeg/2.png';
import Three from '../img/activeg/3.png';
import Four from '../img/activeg/4.png';
import Five from '../img/activeg/5.png';
import Six from '../img/activeg/6.png';
import Seven from '../img/activeg/7.png';
import Eight from '../img/activeg/8.png';
import Nine from '../img/activeg/9.png';
import Ten from '../img/activeg/10.png';
import Eleven from '../img/activeg/11.png';
import Tweleve from '../img/activeg/12.png';
import Thirteen from '../img/activeg/13.png';
import Fourteen from '../img/activeg/13.png';
import Fiveteen from '../img/activeg/13.png';
import Sixteen from '../img/activeg/13.png';
import Seventeen from '../img/activeg/13.png';
import Eightteen from '../img/activeg/13.png';

class ActiveG extends React.Component {
    render() {
        return (
            <div>
                <h1>ActiveG</h1>
                <img src={One}></img>
            </div>
        );
    }
}

export default {
  ActiveG
}