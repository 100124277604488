import PCBTree from './photos/pcb_tree.png';
import CoverPhoto from './photos/pcb_tree.png';

const photos = [
    {
        src: PCBTree,
    },
]

export default {
    COVER_PHOTO: CoverPhoto,
    photos,
}