import React from 'react';


class MedProject extends React.Component {
    render() {
        return (
            <div>
                
            </div>
        );
    }
}

export default {
  MedProject
}