import React from 'react';


class IcedDev extends React.Component {
    render() {
        return (
            <div>
                
            </div>
        );
    }
}

export default {
  IcedDev
}